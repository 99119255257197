import { LegacyRef } from 'react'
import { ExtraTextInBizPosterType, templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';

export default function BizImageTemplate4({
    templateData, bizRef, rawImg, onLoadRawImg, extraText
}: {
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void,
    extraText?: Array<ExtraTextInBizPosterType>
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    {
                        templateData.logo ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '10%', left: '10%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.logo}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '100px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                            : ''
                    }
                    {
                        templateData.profile ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '86%', left: '10%', transform: 'translate(-10%, -50%)' }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={templateData.profile}
                                        alt={templateData.bnmae ?? 'Boostify-Biz'}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <div style={{ marginLeft: '10px', color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '20px 0 20px 0', padding: '15px' }}>
                                        <div style={{ fontSize: '40px', fontWeight: 'bolder' }}>{templateData?.pname}</div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '5%', right: '10%', width: '80%' }}
                    >
                        <div className="business-name" style={{ textAlign: 'right', margin: '0 auto', fontSize: '40px', color: 'black', fontWeight: 'bolder', }}>
                            <div style={{ background: 'rgba(0,0,0,0.5)', color: 'white', display: 'inline-block', padding: '10px 10px', borderRadius: '25px' }}>
                                {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
                            </div>
                        </div>
                    </div>
                    <div
                        className="biz-contact-info"
                        style={{ position: 'absolute', top: '90%', left: '5%', width: '90%', }}
                    >
                        <div className="biz-primary-contact" style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around', gap: 5, color: 'white', background: 'rgba(0,0,0,0.5)', borderRadius: '0 20px 0 20px', }}>
                            <span style={{ fontSize: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CallIcon />{templateData?.mobile}</span>
                            <span>{" "}</span>
                            <span style={{ fontSize: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><EmailIcon />{templateData?.mail}</span>
                            <span>{" "}</span>
                            <span style={{ fontSize: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><LanguageIcon />{templateData?.web}</span>
                        </div>
                        <div className="biz-sm-data" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '5px', gap: '50px', paddingBottom: '10px' }}>
                            {
                                templateData?.sm?.fb ?
                                    <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={fbIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.insta ?
                                    <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={instaIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.tw ?
                                    <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={xIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.yt ?
                                    <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={ytIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                        </div>
                    </div>
                    {
                        templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>


                    {
                        extraText?.map((text, index) => (
                            <div
                                key={index}
                                style={{ ...text.style, position: 'absolute' }}
                            >
                                {text.text}
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}
